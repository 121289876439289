<template>
  <v-container id="accounts" fluid tag="section">
    <v-container class="py-0">
      <form novalidate>
        <md-card-content class="custom-card-cont">
          <v-card class="card-cont">
            <v-row
              class="account-search-bar"
              justify="space-between"
              align="center"
            >
              <v-col cols="5" class="header">
                <h3>Global Account List</h3>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="accountFilter"
                  :items="accountFilterOptions"
                  label="Filter accounts"
                  @change="onFiltersChange"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="search"
                  label="Company Name"
                  @input="debounceSearch"
                />
              </v-col>
              <v-col cols="3" class="btn-cont">
                <v-btn
                  class="ma-2 mt-3 color_class"
                  dark
                  outlined
                  id="clear-filters"
                  @click="clearFilters"
                >
                  Clear filters
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </md-card-content>
      </form>
    </v-container>
    <v-row v-if="accounts.length && !loading">
      <v-col cols="12">
        <v-container class="py-0">
          <v-row class="header-container">
            <v-col cols="10" class="text-element-2">Account</v-col>
            <v-col cols="2" class="btn-cont">
              <v-btn color="primary" @click="openDialog"> Add </v-btn>
            </v-col>
          </v-row>
          <div>
            <v-card
              class="card-cont"
              v-for="account in accounts"
              :key="account.id"
            >
              <v-row class="account-container">
                <v-col cols="7" class="text-element">
                  <span>{{ account.name }}</span>
                </v-col>
                <v-col cols="2" class="center-aligned text-element">
                  <v-chip
                    :style="
                      account.type === 1
                        ? {
                            background: 'rgba(71, 48, 104, 0.1)',
                            color: '#442d65',
                          }
                        : { background: '#fff2f0', color: '#ea7a66' }
                    "
                  >
                    {{ account.type_details.name }}
                  </v-chip>
                </v-col>
                <v-col cols="3" class="action-container">
                  <v-btn
                    @click="editAccount(account.id, account.name)"
                    color="primary"
                    class="action"
                  >
                    <v-icon>mdi-file-document-edit</v-icon>
                  </v-btn>
                  <v-btn
                    @click="deleteAccount(account.id)"
                    color="error"
                    class="action"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <Pagination
            style="margin-right: -30px"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="accounts.length"
          />
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else-if="loading">
      <v-col cols="12" class="center-aligned">
        <md-progress-spinner
          class="color_stroke"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="center-aligned">
        <p>No accounts found</p>
      </v-col>
    </v-row>
    <template>
      <popup-window
        :active="dialogVisible"
        :title="
          isNew ? 'Add items to global list' : 'Edit items in global list'
        "
        :hasBack="false"
        @popup-close="close"
        @popup-back="close"
      >
        <div>
          <div>
            <v-form ref="accountForm" class="mt-5">
              <div>
                <v-text-field
                  v-model="model.name"
                  label="Name"
                  outlined
                  clearable
                  :rules="[rules.required, rules.maxLength]"
                  dense
                />
              </div>
              <div>
                <v-select
                  v-if="isNew"
                  v-model="model.type"
                  outlined
                  :items="accountFilterOptions"
                  label="Type"
                  :rules="[rules.required]"
                  dense
                ></v-select>
              </div>
            </v-form>
          </div>
          <div>
            <ui-button class="primary long" @click="save" title="Submit" :disabled="submitDisabled" />
          </div>
        </div>
      </popup-window>
    </template>
  </v-container>
</template>

<script>
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      totalCount: 0,
      submitDisabled: false,
      dialogVisible: false,
      isNew: true,
      selectedAccountId: null,
      search: "",
      accountFilter: null,
      accounts: [],
      accountFilterOptions: [
        { text: "Brand", value: 1 },
        { text: "Supplier", value: 2 },
      ],
      model: {
        name: null,
        type: null,
      },
      rules: {
        required: (value) => !!value || "This field is Required.",
        maxLength: (value) =>
          (value && value.length <= 255) || "Maximum 255 characters is allowed",
      },
    };
  },
  mounted() {
    this.fetchAccounts();
  },
  methods: {
    fetchAccounts() {
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        this.currentPage +
        "&name=" +
        (this.search ? this.search : "");

      if (this.accountFilter === 1) {
        url += "&type=brand";
      } else if (this.accountFilter === 2) {
        url += "&type=supplier";
      }

      this.loading = true;
      restAdapter
        .get(url)
        .then((response) => {
          this.accounts = response.data.data;
          this.totalCount = response.data.total;
          this.loading = false;
        })
        .catch((error) => {
          this.accounts = [];
          this.loading = false;
        });
    },

    deleteAccount(accountId) {
      if (confirm("Delete this account?")) {
        restAdapter
          .delete("/api/organization_registry/" + accountId)
          .then((response) => {
            notification.success("Account deleted successfully");
            this.fetchAccounts();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              notification.error(
                "Failed to delete organization. This organization already has an account."
              );
            } else {
              notification.error("Account not deleted successfully");
            }
          });
      }
    },

    editAccount(accountId, name) {
      this.isNew = false;
      this.selectedAccountId = accountId;
      this.model.name = name;
      this.openDialog();
    },

    debounceSearch: debounce(async function () {
      this.currentPage = 1;
      this.fetchAccounts();
    }, 200),

    onFiltersChange() {
      this.currentPage = 1;
      this.fetchAccounts();
    },

    loadPage(page) {
      this.currentPage = page;
      this.fetchAccounts();
    },

    openDialog() {
      if (this.selectedAccountId == null) {
        this.isNew = true;
      }
      this.dialogVisible = true;
    },

    save() {
      if (!this.$refs.accountForm.validate()) {
        return;
      }
      if (this.isNew) {
        this.createAccount();
      } else {
        this.updateAccount();
      }
      this.$refs.accountForm.resetValidation();
    },

    createAccount() {
      this.submitDisabled = true;
      const formData = new FormData();
      formData.append("name", this.model.name);
      formData.append("type", this.model.type);

      restAdapter
        .post("/api/organization_registry", formData)
        .then((response) => {
          notification.successWithAlert("Account added successfully");
          this.fetchAccounts();
          this.close();
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later");
        })
        .finally(() => {
          this.submitDisabled = false;
        });
    },

    updateAccount() {
      this.submitDisabled = true;
      restAdapter
        .put(`/api/organization_registry/${this.selectedAccountId}`, {
          name: this.model.name,
        })
        .then((response) => {
          notification.success("Account updated successfully");
          this.accounts = this.accounts.map((account) => {
            if (account.id === this.selectedAccountId) {
              account.name = this.model.name;
            }
            return account;
          });
          this.close();
        })
        .catch((error) => {
          notification.error("Account updated unsuccessfully");
        })
        .finally(() => {
          this.submitDisabled = false; 
        });
    },

    clearFilters() {
      this.accountFilter = null;
      this.search = "";
      this.currentPage = 1;
      this.fetchAccounts();
    },

    close() {
      this.model.name = null;
      this.model.type = null;
      this.selectedAccountId = null;
      this.dialogVisible = false;
      this.$refs.accountForm.resetValidation();
    },
  },
};
</script>

<style scoped>
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}

.verification-btn {
  width: 100px;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}

.verification-success {
  background-color: #64d5ca;
}

.verification-error {
  background-color: #f66d9b;
}

.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}

.account-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}

.popup-card {
  padding: 20px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  z-index: 100;
}

.centered-content {
  margin: 0 auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  flex: 1;
  font-weight: bold;
  text-align: left;
}

.v-menu__content {
  z-index: 250 !important;
}

.separator {
  margin: 0 10px;
  text-align: center;
}

.data {
  flex: 2;
  text-align: left;
}

.center-aligned {
  text-align: center;
  margin-top: 20px;
}

.account-search-bar {
  background: #ffffff;
  border-radius: 7px;
}

.btn-cont {
  text-align: right;
}

.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}

.header {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  color: #473068;
}

.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}

.account-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}

.action-container {
  display: flex;
  justify-content: flex-end;
}

.custom-text-field ::v-deep label {
  color: #442d65;
}

.custom-card-cont {
  margin: 0px -15px;
}

.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}

.action {
  width: 10px !important;
  /* border-radius: 40%; */
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}

.text-element-1 {
  padding-left: 0px;
}

.text-element-2 {
  padding-left: 4px;
}

.theme--light.v-input {
  margin-right: 20px;
}

.theme--light.v-label {
  color: #442d65 !important;
}

.mdi-magnify::before {
  color: #442d65 !important;
}

.mdi-magnify::after {
  color: #442d65 !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
</style>
<style>
.v-menu__content {
  z-index: 1000 !important;
}
</style>
